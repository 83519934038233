import { useState } from 'react';
import { BreachDetail, SearchBreach } from '@shared/components';
import { PortalResponse } from '@shared/type-definitions'

export default function App(): JSX.Element {
  const [targetCase, setTargetCase] = useState<PortalResponse>();

  return (
    <div>
      {targetCase ?
        <div id='detail-container'>
          <BreachDetail
            orgId='cityOfMooneeValley'
            parkingCase={targetCase}
            format='table'
            title={{
              variant: 'h4',
              text: (plate) => `Infringement details for ${plate}`
            }}
            breachNumberText='Infringement Number'
            handleBackButton={() => setTargetCase(undefined)}
            showImages
          />
        </div>
      : <div id='search-container'>
          <SearchBreach
            orgId='cityOfMooneeValley'
            title='Search Infringement'
            breachInputText='Infringement'
            plateInputText='Licence Plate'
            validateSubmission={(plate: string, breachId: string) => {
              const breachIdNumber = Number(breachId);
              if (!isNaN(breachIdNumber) && breachIdNumber < 6_000_000) {
                window.location.href = 'https://viewmyinfringement.mvcc.vic.gov.au/legacy';
                return false;
              }
              return true;
            }}
            onSubmit={(parkingCase: PortalResponse) => {
              setTargetCase(parkingCase);
            }}
            caseNotFoundErrorText='We could not find your breach.'
            cancelledCaseErrorText='Your breach has been cancelled.'
          />
        </div>
      }

{/*       {(breachId && plate) &&
        <div id='detail-container'>
          <BreachDetail
            orgId='cityOfMooneeValley'
            breach={breachId}
            plate={plate}
            format='table'
            titleText={`Infringement details for ${plate}`}
            breachNumberText='Infringement Number'
            caseNotFoundErrorText='We could not find your breach.'
            cancelledCaseErrorText='Your breach has been cancelled.'
            onLoading={(isLoading) => setIsLoading(isLoading)}
            handleBackButton={() => {
              setBreachId(undefined);
              setPlate(undefined);
            }}
            waitUntilLoaded
          />
        </div>} */}
{/*       <div id='search-container'>
        <SearchInfringement onData={handleDataReceived} isLoading={isLoading}/>
      </div> */}
    </div>
  )
}
