import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface InputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

export function Input({ label, onChange, value }: InputProps): JSX.Element {
  return (
    <div className='breach-input'>
      <Typography component='p' className='breach-input-label'>
        {label} <span className='required-text'>*</span>
      </Typography>
      <TextField className='breach-input-text-field' required variant='outlined' size='small' onChange={(e) => onChange(e.target.value)} value={value}/>
    </div>
  );
}