import { useState, FormEvent } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { Input } from '../Input';
import { PortalResponse } from '../../../type-definitions/src/api';
import { getParkingDetails } from '../../../utils/src/breach-portal/parking';

import './styles.css';

interface SearchBreachProps {
  orgId: string;
  title?: string;
  plateInputText: string;
  breachInputText: string;
  validateSubmission?: (plate: string, breach: string) => boolean;
  onSubmit: (parkingCase: PortalResponse, breachId: string, plate: string) => void;
  caseNotFoundErrorText: string;
  cancelledCaseErrorText: string;
  closedCaseErrorText?: string;
  elevation?: number;
}

export function SearchBreach({ orgId, title, plateInputText, breachInputText, validateSubmission, caseNotFoundErrorText,
    cancelledCaseErrorText, closedCaseErrorText, onSubmit, elevation }: SearchBreachProps): JSX.Element {
  const [breach, setBreach] = useState<string>('');
  const [plate, setPlate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<{ message: string, type: 'success' | 'error' }>();

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valid = validateSubmission?.(plate, breach) ?? true;

    if (errorMessage) {
      setErrorMessage(undefined);
    }

    if (valid) {
      setIsLoading(true);
      try {
        const caseDetails = await getParkingDetails(orgId, breach, plate);
        if (caseDetails.case.cancelled) {
          setErrorMessage({ message: cancelledCaseErrorText, type: 'success' });
        } else if (caseDetails.case.state === 'Closed' && closedCaseErrorText) {
          setErrorMessage({ message: closedCaseErrorText, type: 'success' });
        }
        onSubmit(caseDetails, breach, plate);
      } catch (e: unknown) {
        if (e instanceof Error) {
          if (e.message === '404') {
            setErrorMessage({ message: caseNotFoundErrorText, type: 'error' });
          } else {
            setErrorMessage({ message: 'An unexpected error occurred when finding your request.', type: 'error' });
          }
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <Paper className='breach-container' elevation={elevation ?? 15}>
      {title && <Typography variant='h4'>{title}</Typography>}
      <form className='search-form-container' style={{ marginTop: title ? '25px' : 0 }} onSubmit={handleFormSubmit}>
        <div id='form-inputs'>
          <Input label={breachInputText} onChange={(value: string) => setBreach(value)} value={breach}/>
          <Input label={plateInputText} onChange={(value: string) => setPlate(value)} value={plate}/>
        </div>
        <Typography component='p'>
          <span className='required-text'>*</span> Indicates required fields
        </Typography>
        {errorMessage && <div className={errorMessage?.type === 'success' ? 'error-message-success' : 'error-message'}>
          <Typography component='span'>
            {errorMessage.message}
            </Typography>
          </div>
        }
        {isLoading ?
          <div id='submit-loading'><CircularProgress/></div>
        : <Button id='search-breach-button' type='submit' variant='contained'>SEARCH</Button>}
      </form>
    </Paper>
  )
}
