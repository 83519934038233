import Typography from '@mui/material/Typography';
import Gallery from 'react-photo-gallery';

import { CustomerResource } from '../../../type-definitions/src/api';

interface PhotoGalleryProps {
  resources: CustomerResource[];
}

export default function PhotoGallery({ resources }: PhotoGalleryProps) {
  const photos = resources.filter((it) => it.contentType && it.contentType.startsWith('image/'))
    .map((img) => ({ src: img.publicUrl!, width: 1, height: 1 }));

  return (
    <>
      {photos.length > 0 ?
        <div style={{ display: 'flex', flexFlow: 'wrap' }}>
          {photos.map((photo) => (
            <div>
              <img src={photo.src} style={{ margin: '2px', display: 'block', maxHeight: '275px', maxWidth: '275px', width: 'auto' }}/>
            </div>
          ))}
        </div>
      : <Typography>No breach pictures found</Typography>}
    </>
  )
}
