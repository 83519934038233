import { ReactNode, useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowBack from '@mui/icons-material/ArrowBack';
import AttachMoney from '@mui/icons-material/AttachMoney';
import LocationSearching from '@mui/icons-material/LocationSearching';
import Policy from '@mui/icons-material/Policy';
import Receipt from '@mui/icons-material/Receipt';
import Schedule from '@mui/icons-material/Schedule';
import { Variant } from '@mui/material/styles/createTypography';

import { PayBreach } from './PayBreach';
import PhotoGallery from './PhotoGallery';
import { PortalResponse, CustomerOffence } from '../../../type-definitions/src/api';

import './styles.css';

interface BreachDetailProps {
  orgId: string;
  parkingCase: PortalResponse;
  handleBackButton: () => void;
  title: {
    text: string | ((plate: string, breach: string) => string);
    variant: Variant;
  }
  breachNumberText: string;
  format: 'table' | 'list';
  showImages?: boolean;
  /** Pay Now button appears if supplied */
  payNow?: {
    stripeKey: string;
    buttonText: string;
    successUrl: string;
    cancelUrl: string;
  }
}

export function BreachDetail({ orgId, parkingCase, handleBackButton, format, payNow, showImages, title, breachNumberText }: BreachDetailProps)
    : JSX.Element {

  return (
    <Paper className='breach-container' elevation={15}>
      <div className='breach-container-header'>
        <IconButton
          component='span'
          onClick={() => handleBackButton()}
          id='back-button'
          size="large">
          <ArrowBack style={{ color: 'var(--theme-secondary-text)' }}/>
        </IconButton>
        <Typography variant={title.variant} component='span'>
          {typeof title.text === 'string' ? title.text : title.text(parkingCase.vehicleDetails.plate, parkingCase.breachNotice!.breachId)}
        </Typography>
      </div>
      {format !== 'table' && <Divider style={{ backgroundColor: 'grey' }}/>}
      {format === 'table' && <DetailTable breachNumberText={breachNumberText} parkingCase={parkingCase}/>}
      {format === 'list' && <DetailList breachNumberText={breachNumberText} parkingCase={parkingCase}/>}
      {payNow && <PayBreach
        orgId={orgId}
        buttonText={payNow.buttonText}
        successUrl={payNow.successUrl}
        cancelUrl={payNow.cancelUrl}
        caseUri={parkingCase.case.uri}
        breachId={parkingCase.breachNotice!.breachId ?? parkingCase.ticket!.breachId}
        stripeKey={payNow.stripeKey}
      />}
      {showImages && <PhotoGallery resources={parkingCase.resources}/>}
    </Paper>
  );
}

interface DetailListProps {
  breachNumberText: string;
  parkingCase: PortalResponse;
}

function DetailList({ breachNumberText, parkingCase }: DetailListProps) {
  const formatDate = (time?: string, options?: Intl.DateTimeFormatOptions): string | undefined => {
    if (!time) {
      return undefined;
    }
    return new Intl.DateTimeFormat('en', options).format(new Date(time));
  }

  const formatAmountOwing = (offence: CustomerOffence): ReactNode => {
    if (offence.discount) {
      return (
        <>
          <del>{offence.offenceFeeFormatted}</del>
          <span style={{ marginLeft: '10px' }}>{offence.discount.discountedFeeFormatted} - Early payment discount ({offence.discount.discountAmountFormatted} off)</span>
        </>
      );
    }
    return offence.offenceFeeFormatted;
  }

  return (
    <div className='list-container'>
      <List>
        <DetailListItem
          primary={breachNumberText}
          secondary={parkingCase.breachNotice?.breachId}
          Icon={Receipt}
        />
        <DetailListItem
          primary='Offence Date'
          secondary={formatDate(parkingCase.overstayOffenceTime, { year: 'numeric', month: 'short', day: 'numeric' }) ?? 'N/A'}
          Icon={Schedule}
        />
        <DetailListItem
          primary='Location'
          secondary={Boolean(parkingCase.location.address) ? parkingCase.location.address
            : Boolean(parkingCase.location.name) ? parkingCase.location.name
            : parkingCase.bayName}
          Icon={LocationSearching}
        />
        <DetailListItem
          primary='Offence'
          secondary={parkingCase.offences[0]?.offenceMessage ?? 'Other'}
          Icon={Policy}
        />
        <DetailListItem
          primary='Amount Owing'
          secondary={formatAmountOwing(parkingCase.offences[0])}
          Icon={AttachMoney}
        />
      </List>
    </div>
  );
}

interface DetailListItemProps {
  primary: ReactNode;
  secondary?: ReactNode;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

function DetailListItem({ primary, secondary, Icon }: DetailListItemProps): JSX.Element {
  return (
    <ListItem>
      <ListItemAvatar>
        <Icon/>
      </ListItemAvatar>
      <ListItemText
        primary={
          <span className='list-item-primary'>
            {primary}
          </span>
        }
        secondary={
          <span className='list-item-secondary'>
            {secondary}
          </span>
        }
      />
    </ListItem>
  )
}

interface DetailTableProps {
  breachNumberText: string;
  parkingCase: PortalResponse;
}

function DetailTable({ breachNumberText, parkingCase }: DetailTableProps) {
  const formatDate = (time?: string, options?: Intl.DateTimeFormatOptions): string | undefined => {
    if (!time) {
      return undefined;
    }
    return new Intl.DateTimeFormat('en', options).format(new Date(time));
  }
  return (
    <table id='breach-table'>
      <thead>
        <tr className='breach-table-row'>
          <TableHead label={breachNumberText}/>
          <TableHead label='Offence Date'/>
          <TableHead label='Offence Time'/>
          <TableHead label='Location'/>
          <TableHead label='Amount'/>
          <TableHead label='Violation'/>
        </tr>
      </thead>
      <tbody>
        <tr className='breach-table-row'>
          <TableCell value={parkingCase.breachNotice?.breachId ?? 'N/A'}/>
          <TableCell value={formatDate(parkingCase.overstayOffenceTime, { year: 'numeric', month: 'short', day: 'numeric' }) ?? 'N/A'}/>
          <TableCell value={formatDate(parkingCase.overstayOffenceTime, { hour: 'numeric', minute: 'numeric', second: 'numeric' }) ?? 'N/A'}/>
          <TableCell value={parkingCase.location.address ? `${parkingCase.location.name ?? ''} - near ${parkingCase.location.address}` : (parkingCase.location.name ?? 'N/A')}/>
          <TableCell value={`$${parkingCase.offences[0].offenceFee ? parkingCase.offences[0].offenceFee.toFixed(2) : '0.00'}`}/>
          <TableCell value={parkingCase.offences[0].offenceMessage ?? 'N/A'}/>
        </tr>
      </tbody>
    </table>
  )
}

interface TableHeadProps {
  label: ReactNode,
}

function TableHead({ label }: TableHeadProps): JSX.Element {
  return (
    <th className='breach-table-head'>
      <Typography className='title-header-label'>{label}</Typography>
    </th>
  )
}

interface TableCellProps {
  value: ReactNode;
}

function TableCell({ value }: TableCellProps): JSX.Element {
  return (
    <td>
      <Typography className='field-value'>{value}</Typography>
    </td>
  );
}
